function Header() {
  return (
    <div class="header-section">
      <div class="row">
        <div class="col-lg-8">
          <div class="header-content" style={{ display: "flex" }}>
            <p style={{ color: "black" ,marginLeft:'5px'}}>
              <i class="flaticon-call"> Contact Number - </i>
              <a href="tel:+91 7980427142"> +91 7980427142</a> (Susmita das)/ <a href="tel:+916289079248">+91 6289079248</a> (Afsari Begum)
            </p>
            </div>
            <div >
            <p style={{ color: "black", marginLeft: "5px" }}>
              <i class="flaticon-email"></i> Email ID - 
              <a href="muktokanthomahilasamity@gmail.com">muktokanthomahilasamity@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
