import Project from "./Project";
import "../Styles/App.css";
import { BrowserRouter as Router,Routes,Route,Switch } from "react-router-dom";
import Image from "./Image";
import Login from "./Login";
import ImageUpload from "./ImageUpload";
import UploadImages from "./UploadImages";
import Locationupload from "./Locationupload";
import Createupload from "./Createupload";
import Updateupload from "./Updateupload";
import UpdateLocationupload from "./UpdateLocation";
import Deleteupload from "./Deleteupload";
//import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <Router>
      <Routes>
        
          <Route exact path="/" element={<Project/>}/>
          <Route  path="/ourwork" element={<Image/>}/>
          <Route path="/admin-login" element={<Login/>}/>
          <Route path="/admin-dashboard" element={<ImageUpload/>}/>
          <Route path="/admin-images-upload" element={<UploadImages/>}/>
          <Route path="/admin-location-upload" element={<Locationupload/>}/>
          <Route path="/create-upload" element={<Createupload/>}/>
          <Route path="/admin-images-upload/update/:component_id" element={<Updateupload/>}/>
          {/* <Route path="/admin-images-upload/delete/:component_id" element={<Deleteupload/>}/> */}
          <Route path="/admin-location-upload/updatelocation/:component_id" element={<UpdateLocationupload/>}/>
          

        
      </Routes>
      
    </Router>
  );
}

export default App;
