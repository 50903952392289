import React, { useEffect, useState } from "react";
import "../Styles/Slideshow.css";
import LandingImage1 from "../images/Landing Image 1.jpeg";
import LandingImage2 from "../images/Landing Image 2.jpeg";
import LandingImage3 from "../images/Landing Image 3.jpeg";
import LandingImage4 from "../images/Landing Image 4.jpeg";
function Slideshow() {
  useEffect(() => {
    let slideIndex = 0;
    // displaySlides();

    // function displaySlides() {
    //   let slides = document.getElementsByClassName("image-slide");
    //   for (let i = 0; i < slides.length; i++) {
    //     slides[i].style.display = "none";
    //   }
    //   slideIndex++;
    //   if (slideIndex > slides.length) {slideIndex = 1}
    //   slides[slideIndex-1].style.display = "block";
    //   setTimeout(displaySlides, 10000); // Change image every 2 seconds
    // }
  });
  return (
    <div>
      <div class="image-text-slideshow-container">
        <div class="image-slide">
          <img src={LandingImage1} alt="Image Slide 1"></img>
          <div class="text">WomenCollectiveImage-Text-1</div>
        </div>
        <div class="image-slide">
          <img src={LandingImage2} alt="Image Slide 2"></img>
          <div class="text">WomenCollectiveImage-Text-2</div>
        </div>
        <div class="image-slide">
          <img src={LandingImage3} alt="Image Slide 3"></img>
          <div class="text">WomenCollectiveImage-Text-3</div>
        </div>
        <div class="image-slide">
          <img src={LandingImage4} alt="Image Slide 4"></img>
          <div class="text">WomenCollectiveImage-Text-4</div>
        </div>
      </div>
    </div>
  );
}
export default Slideshow;
