import React ,{useState,useEffect} from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const MapComponent = () => {
  //Set initial position and zoom level
  const position = [22.5726, 88.3639];
  const [getMapLocationData, setMapLocationData] = useState([]);
  const zoom = 8;
  useEffect(() => {
    axios
      .get("https://admin.muktokantho.co.in/api/uicomponenet/admin-images-upload")
      .then((res) => setMapLocationData(res.data.components))
      .catch((err) => console.log(err));
  }, []);
  
  return (
    <div id="map">
      
      {getMapLocationData.map((data, i) => {
        
        return(
        <div>
          {
            data.component_type==='Map'&&<div>
              <iframe
        width="100%"
        height="450"
        loading="lazy"
        allowFullScreen
        src={data.component_path}
        frameBorder="0"
      ></iframe>
            </div>
          }
        </div>
      )
      }
        
      

      )}
               
              
           
                
             
                   
      {/* <iframe
        width="100%"
        height="450"
        loading="lazy"
        allowFullScreen
        src=""
        frameBorder="0"
      ></iframe> */}
      {/* // <iframe  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19423.687791569337!2d88.4639909082018!3d22.592367387170757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a027635538831c7%3A0x7fac822d6efbc521!2sTata%20Consultancy%20Services%20Limited%2C%20Gitanjali%20Park!5e0!3m2!1sen!2sin!4v1709699431162!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      
      
  
      </div>
    
   );
  // const [position, setPosition] = useState([51.505, -0.09]);
  // const [markers, setMarkers] = useState([
  //   { position: [51.505, -0.09], content: "Marker 1" },
  //   { position: [51.51, -0.1], content: "Marker 2" }
  // ]);
  // const handleMapClick = (event) => {
  //   const { lat, lng } = event.latlng;
  //   setMarkers([...markers, { position: [lat, lng], content: "New Marker" }]);
  // };
  // return (
  //   <div>
  //   <MapContainer center={position} zoom={8} onClick={handleMapClick} style={{ height: '400px', width: '100%' }} zoomControl={false}>
  //     <TileLayer
  //       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  //     />
  //     {markers.map((marker, index) => (
  //       <Marker key={index} position={marker.position}>
  //         <Popup>{marker.content}</Popup>
  //       </Marker>
  //     ))}
  //   </MapContainer>
  //   </div>
  // )
};

export default MapComponent;
