import React, { useState, useEffect } from "react";
import womencollectivelogo from "../logo/women-collective-image-3.png";
import axios from "axios";
import { Link } from "react-router-dom";

import styled from "styled-components";
const Styles = styled.div`
  .styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width: -webkit-fill-available;
    margin-left: 10%;
  }

  .styled-table thead tr {
    background-color: #20013e;
    color: #ffffff;
    text-align: left;
  }

  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
  }

  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #20013e;
  }
  .active-row:hover {
    background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  }
  .button {
    align-items: center;
    background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 20px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
  }

  .button:active,
  .button:hover {
    outline: 0;
  }

  .button span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
  }

  .button:hover span {
    background: none;
  }

  @media (min-width: 768px) {
    .button {
      max-width: 20px;
    }
  }
  
`;

function UploadImages() {
  const [selectImage, setSelectImage] = useState(null);
  const [getUploadedImageData, setUploadedImageData] = useState([]);
  const formData = [];
  const handleUploadedImage = (e) => {
    setSelectImage(e.target.files[0]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the image submission logic here, e.g., send the image to the backend
    // if (selectImage) {
    //   const formData = new FormData();
    //   formData.append("image", selectImage);
    //   // Perform actions like sending formData to your backend API using fetch or Axios
    // }
    // try {
    //   const respo = axios.post("/images/upload", formData);
    // } catch (error) {}
  };
  const handleDelete = async (component_id) => {
    try {
      await axios.delete(
        "https://admin.muktokantho.co.in/api/uicomponenet/delete-images-upload/" +
          component_id
      );
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  // useEffect(() => {
  //   axios
  //     .get("http://localhost:8000/api/uicomponenet/admin-images-upload")
  //     .then((res) => setUploadedImageData(res.data.components))
  //     .catch((err) => console.log(err));
  // }, []);
  useEffect(()=>{
    const apicall2=async ()=>{
    try{
      let table3=[];
      const response3= await axios.get('https://admin.muktokantho.co.in/api/uicomponenet/admin-images-upload');
      console.log("ki3",response3.data.components)
      let result3=await response3.data.components;
      
      result3.forEach(d=>{
        if(d.component_type==='Image' ){
      
        table3.push(d)
      
        }
      })
      
    
 
    console.log("kdkdk33",table3);
    setUploadedImageData(table3);
    
  }
  catch(error){
    console.log("Error fetching apidata:",error)
  }
  };
  apicall2();
},[])
  
  return (
    <Styles>
      <div>
        <a class="navbar-brand" href="http://muktokantho.co.in/admin-dashboard">
          <img
            src={womencollectivelogo}
            class="white-logo"
            alt="logo"
            style={{ width: "10%", height: "80px" }}
          ></img>
        </a>
        <div class="space"></div>
        {/* <form onSubmit={handleSubmit}> */}
          {/* <div>
            <label htmlFor="imageUpload">Choose an image:</label>
            <input
              type="file"
              id="imageUpload"
              onChange={handleUploadedImage}
            />
          </div> */}
          <Link to="/create-upload" style={{ textDecoration: "none" }}>
            <button class="button" role="button">
              <span class="text">Upload</span>
            </button>
          </Link>
        {/* </form> */}
        <div style={{ width: "90%" }}>
          <table className="styled-table">
            <thead>
              <tr>
                <th>Component ID</th>
                <th>Component Type</th>
                <th>Component Path</th>
                <th>Component Text</th>
                <th>Component Position</th>
              </tr>
            </thead>
            <tbody>
              {getUploadedImageData.map((data, i) => (
                  <tr class="active-row">
                    
                
                  
                  <td>{data.component_id}</td>
                  <td>{data.component_type}</td>
                  <td>{data.component_path}</td>
                  <td>{data.component_text}</td>
                  <td>{data.component_position}</td>
                  <td>
               
                    <button >
                      <Link style={{ textDecoration: "none",color:"#ffffff"}} to={`update/${data.component_id}`}>Update</Link>
                    </button>
                  
                    
                    <button onClick={(e) => handleDelete(data.component_id)}>
                      Delete
                    </button>
                     
                  </td>
                  

                
                </tr>
                
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Styles>
  );
}
export default UploadImages;

{
  /* <table class="styled-table">
    <thead>
        <tr>
            <th>Name</th>
            <th>Points</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Dom</td>
            <td>6000</td>
        </tr>
        <tr class="active-row">
            <td>Melissa</td>
            <td>5150</td>
        </tr>
        <!-- and so on... -->
    </tbody>
</table> */
}
