import React,{useState} from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

function UpdateLocationupload(){
    // const[location_ids,setlocation_ids]=useState('');
    const[component_path,setcomponent_path]=useState('');
    const{component_id}=useParams();
    const navigate=useNavigate();
    function handleSubmit(event){
        event.preventDefault();
        console.log("kmol1",component_id)
        console.log("kmol",component_path)
        axios.put('https://admin.muktokantho.co.in/api/uicomponenet/update-location-upload/'+component_id,{component_path})
        .then(res=>{
            console.log(res);
            navigate('/admin-location-upload')
        }).catch(err=>console.log(err))
    }

    return(
        <div>
            <div className=''>
                <div className=''>
                    <form onSubmit={handleSubmit}>
                        <h2>Update Location</h2>

                        {/* <div className='mb-2'>
                            <label htmlFor=''>Location ID</label>
                            <input type='text' placeholder='Enter Location Id' onChange={e=>setlocation_ids(e.target.value)}/>
                        </div> */}
                    
                        <div className='mb-2'>
                            <label htmlFor=''>Location Path</label>
                            <input type='text' placeholder='Enter Location Path' onChange={e=>setcomponent_path(e.target.value)}/>
                        </div>
                        
                    
                        <button>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default UpdateLocationupload;