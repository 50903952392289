import React, { useEffect, useState } from "react";
import "../Styles/Project.css";
import Footer from "./footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Donation from "./Donation";
import Description from "./Description";
import Subscribe from "./Subscribe";
import Blogs from "./Blogs";
import Moments from "./Moments";
import MyComponent from "./Donation";
import ImageComponent from "./imageComponent";
import axios from "axios";
import Involve from "./Involve";
import styled from "styled-components";
import MapComponent from "./Map";
import LandingImage1 from "../images/Landing Image 1.jpeg";
import LandingImage2 from "../images/Landing Image 2.jpeg";
import LandingImage3 from "../images/Landing Image 3.jpeg";
import LandingImage4 from "../images/Landing Image 4.jpeg";
import Slideshow from "./Slideshow";
// import jelly1 from "../images/jelly1.JPG";
// import jelly2 from "../images/jelly2.JPG";
// import jelly3 from "../images/jelly3.JPG";
// import jelly4 from "../images/jelly4.JPG";
import howweworkimage from "../images/howweworkimage1.png";

const Styles = styled.div`
  .sub-heading-content {
    font-family: cursive;
  }
  h2 {
    color: #20113e;
  }
  .read-more {
    color: blue;
    cursor: pointer;
    bottom: 0;
    right: 0;
    transition: color 0.3s ease;
  }

  .read-more:hover {
    color: #20113e;
  }
  .details {
    margin-top: 10px;
    padding: 10px;
    background-color: white;
    display: inline-block;
  }
  p {
    color: black;
    font-family: cursive;
  }
  .card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
    transition: background-color 0.3s ease;
  }

  .card:hover {
    background-color: #20113e;
    color: white;
    p {
      color: white;
    }
  }
  #checking {
    font-family: "Arial", sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  .sub-heading {
    white-space: nowrap;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #20013e;
    transition: 0.5s;
    border-radius: 50px;
    font-size: 16px;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center;
  }
  .sub-heading:hover {
    color: white;
    background-color: green;
  }
  .team-member {
    white-space: nowrap;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #20013e;
    transition: 0.5s;
    border-radius: 50px;
    font-size: 16px;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center;
  }
  .team-member:hover {
    color: white;
    background-color: green;
  }
  .App {
    text-align: center;
    margin-top: 50px;
  }
  .slider-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
  }

  button:hover {
    color: #007bff;
  }

  img {
    width: 98%;
    height: 800px;
    margin: 0 20px;
  }
  dots-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .radio-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .radio-label {
    position: relative;
    margin: 0 5px;
    cursor: pointer;
    padding-right: 8px;
    margin-bottom: 20px;
  }

  .radio-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + .radio-dot {
    background-color: #333;
  }
  @keyframes slideFromRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-40%);
    }
  }
  .text {
    position: absolute;
    top: 20%;
    right: 0;
    height:50%;
    width:30%;
    padding: 70px 10px 10px 10px;
    animation: slideFromRight 1s ease-in-out forwards;
    font-size:40px;
    color:blue;
    font-style:italic;
    border-color:pink
   
    
  }

  @keyframes slideFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }

  .image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: slideFromLeft 1s ease-in-out forwards;
    width:100%
  }
`;

// const images = [jelly1, jelly2, jelly3, jelly4];
const images = [LandingImage1, LandingImage2, LandingImage3, LandingImage4];
const text = [
  "LandingImage1",
  "LandingImage2",
  "LandingImage3",
  "LandingImage4",
];

const Project = () => {
  const [data, setData] = useState([]);
  const [text, setText] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  const [currentImage, setCurrentImage] = useState(0);
  const [currentText, setCurrentText] = useState(0);
  let images1=[];
  let text1=[];

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     nextImage();
  //   }, 7000); // Change the interval time (in milliseconds) as needed

  //   return () => clearInterval(intervalId); // Cleanup on component unmount
  // }, [currentImage, currentText]);

  const nextImage = () => {
    console.log("hi")
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    console.log("hi1")
    setCurrentText((prevText) => (prevText + 1) % text.length);
    console.log("hi2")
  };

  const prevImage = () => {
    setCurrentImage(
      (prevImage) => (prevImage - 1 + images.length) % images.length
    );
    setCurrentText((prevText) => (prevText - 1 + text.length) % text.length);
  };

  const goToImage = (index) => {
    setCurrentImage(index);
    setCurrentText(index);
  }; // Empty dependency array ensures that the effect runs only once, similar to componentDidMount

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     nextImage();
  //   }, 4000); // Change the interval time (in milliseconds) as needed

  //   return () => clearInterval(intervalId); // Cleanup on component unmount
  // }, [currentImage]);
  // useEffect(()=>{
  //   axios.get('https://localhost:8000/images',{responseType:'arraybuffer'})
  //   .then((response)=>{
  //     console.log("jfd",response)
  //     const result=new Blob([response.data],{type:'image/jpeg'});
  //     setData(URL.createObjectURL(result));
  //   })
  // })
  
  // Assuming imagesBufferArray is the array of image buffers received from the backend
  
  useEffect(()=>{
    
    const apicall=async ()=>{
    try{
      let images2=[];
      let text2=[];
      const response= await axios.get('https://admin.muktokantho.co.in/api/uicomponenet/admin-images-upload');
      console.log("ki",response.data.components)
      let result1=await response.data.components;
      
      result1.forEach(d=>{
        if(d.component_type==='Image' && d.component_position ==='landing'){
      
        images2.push(d.component_path)
      
        }
      })
        result1.forEach(d=>{
          if(d.component_type==='Image' && d.component_position ==='landing'){
        
          text2.push(d.component_text)
        
          }
      
    })
      console.log("kdkdk22",images2);
      setData(images2);
      setText(text2);
    }
    catch(error){
      console.log("Error fetching apidata:",error)
    }
    };
    apicall();

  // // response.forEach(buffer => {
  // //   // Assuming buffer is in Uint8Array format
  // //   const blob = new Blob([buffer], { type: 'image/png' }); // Adjust the type according to the image format
  // //   const imgUrl = URL.createObjectURL(blob);
  // //   console.log("aa",imgUrl)

  //   // const img = new Image();
  //   // img.onload = function() {
  //   //     // Once the image has loaded, you can append it to the DOM or perform other operations
  //   //     document.body.appendChild(img); // Example: Append the image to the body
  //   // };
  //   // img.src = imgUrl;
  //   // }
  const intervalId = setInterval(() => {
    nextImage();
  }, 7000); // Change the interval time (in milliseconds) as needed

  return () => clearInterval(intervalId); // Cleanup on component unmount

  },[currentImage, currentText])
  return (
    <Styles>
      <div>
        {false ? (
          <p>Loading...</p>
        ) : (
          <div>
            <Header />
            
            <div class="space"></div>
            {/* <Slideshow /> */}
            <div class="space"></div>
            <Navbar />
            <div class="space"></div>
            
          
            
            {/* {data.map((d, i) => (
              <div key={i.Sequence}>
               
                <img src={d.component_path} alt={d.component_text}></img>
              </div>
            ))} */}
            <div class="home-banner-three">
              <div class="home-slides owl-carousel owl-theme owl-loaded owl-drag">
                <div className="App">
                  <div className="slider-container" key={currentImage}>
                    <img
                      className="image"
                      src={data[currentImage]}
                      alt={`Image${currentImage + 1}`}
                    />
                    <div class="text" >{text[currentText]}</div>
                    {/* {data.map((d, i) => (
              
               
                <img className="image" src={d} alt={d.component_text}></img>
              
            ))} */}
                  </div>

                  <div className="radio-container">
                    {data.map((_, index) => (
                      <label key={index} className="radio-label">
                        <input
                          type="radio"
                          name="imageRadio"
                          checked={index === currentImage}
                          onChange={() => goToImage(index)}
                        />
                        <span className="radio-dot"></span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ul>
                <li>
                  <div class="space">
                    <section className="text-section" id="story">
                      <h2 class="sub-heading">
                        THE URBAN HOMELESS WOMENS COLLECTIVE
                      </h2>
                      <p class="sub-heading-content">
                        In recent years, while there has been some recognition
                        of the issue of homelessness, regrettably, the voices of
                        those experiencing homelessness have not been adequately
                        heard. Civil society groups have been at the forefront
                        of efforts to address homelessness, but the inclusion of
                        homeless individuals themselves has been limited. This
                        is particularly evident when it comes to issues
                        concerning homeless women. Surviving on the streets is
                        like overcoming battles every day and this requires a
                        tremendous amount of courage and optimism. Within this
                        community, there exists a natural leadership that
                        remains largely untapped. Thus, in 2019, Iswar Sankalpa
                        embarked upon the journey of building a collective of
                        urban homeless women (voices of the under-represented
                        and marginalized) in Kolkata.
                        {showDetails && (
                          <p class="details">
                            The core aim of the project has been to enhance the
                            capacities of urban homeless women in Kolkata,
                            particularly in four key areas: improving overall
                            living conditions of the homeless population,
                            promoting health and hygiene, creating livelihood
                            opportunities, and securing entitlements. It is
                            while working with and for this community, that the
                            organisation learnt about their lived experiences,
                            needs, and aspirations, which ultimately culminated
                            in the development of a Charter of Demands by the
                            collective members.
                          </p>
                        )}
                        <span className="read-more" onClick={toggleDetails}>
                          {showDetails ? "read less" : "read more..."}
                        </span>
                      </p>
                    </section>
                  </div>
                </li>
                <li>
                  <div class="space">
                    <section id="team">
                      <h2 class="sub-heading">Duties of the office bearers-</h2>
                      <br></br>
                      <span class="sub-heading-content">
                        <ul>
                          <ul>
                            <li class="team-member">President: </li>
                            <br></br>
                            <div className="Points" style={{ display: "flex" }}>
                              <div className="left">
                                <div class="card">
                                  <li>
                                    1. Shall preside over all meeting of the
                                    society
                                  </li>
                                </div>
                                <div class="card">
                                  <li>
                                    2. Take all disciplinary actions in
                                    consultation with the Governing Body
                                  </li>
                                </div>
                              </div>
                              <div className="right">
                                <div class="card">
                                  <li>
                                    3. Advice the Secretary in any matter
                                    requiring urgent attention
                                  </li>
                                </div>
                                <div class="card">
                                  <li>4. Call emergent meeting</li>
                                </div>
                              </div>
                            </div>
                          </ul>

                          <ul>
                            <li class="team-member">Vice- President:</li>
                            <br></br>
                            <div className="Points" style={{ display: "flex" }}>
                              <div className="left">
                                <div class="card">
                                  1. In the absence of the President, the Vice
                                  President shall perform all the duties of the
                                  president
                                </div>
                              </div>
                            </div>
                          </ul>
                          <ul>
                            <li class="team-member">Secretary:</li>
                            <br></br>
                            <div className="Points" style={{ display: "flex" }}>
                              <div className="left">
                                <div class="card">
                                  <li>
                                    2. Shall convene all meetings of the society
                                  </li>
                                </div>
                                <div class="card">
                                  <li>
                                    3. Maintain minute books of all the meetings
                                  </li>
                                </div>
                                <div class="card">
                                  <li>4. Issue general circular and notices</li>
                                </div>
                                <div class="card">
                                  <li>
                                    5. Receives all the applications for
                                    membership which shall be placed before the
                                    Governing Body
                                  </li>
                                </div>
                                <div class="card">
                                  <li>
                                    6. Sign on behalf of the society for all
                                    receipts for all sums received as
                                    subscriptions
                                  </li>
                                </div>
                              </div>
                              <div className="right">
                                <div class="card">
                                  <li>
                                    7. Sign and give pay order on all bills for
                                    payments
                                  </li>
                                </div>
                                <div class="card">
                                  <li>
                                    8. Get the accounts of the society audited
                                    by a Chartered Accountant
                                  </li>
                                </div>
                                <div class="card">
                                  <li>
                                    9. Ensure compliance with statutory
                                    requirements
                                  </li>
                                </div>
                                <div class="card">
                                  <li>
                                    10. Transact all other business subject to
                                    the direction of the Governing Body
                                  </li>
                                </div>
                              </div>
                            </div>
                          </ul>
                          <ul>
                            <li class="team-member">Asst. Secretary:</li>
                            <br></br>
                            <div className="Points" style={{ display: "flex" }}>
                              <div className="left">
                                <div class="card">
                                  <li>
                                    1. In the absence of the Secretary, the
                                    Asst. Secretary shall perform all the duties
                                    of the president
                                  </li>
                                </div>
                              </div>
                            </div>
                          </ul>
                          <ul>
                            <li class="team-member">Treasurer:</li>
                            <br></br>
                            <div className="Points" style={{ display: "flex" }}>
                              <div className="left">
                                <div class="card">
                                  <li>
                                    2. Collect and review all sorts of
                                    subscriptions, donations and grant receipts
                                    thereof
                                  </li>
                                </div>
                                <div class="card">
                                  <li>
                                    3. Maintain and keep cashbook and such other
                                    accounts as are necessary
                                  </li>
                                </div>
                              </div>
                              <div className="right">
                                <div class="card">
                                  <li>
                                    4. Prepare the budget in consultation with
                                    the secretary for consideration of the
                                    Governing Body
                                  </li>
                                </div>
                              </div>
                            </div>
                          </ul>
                        </ul>
                      </span>
                    </section>
                  </div>
                </li>
                <li>
                  <div class="space">
                    <section id="charter">
                      <h2 class="sub-heading"> CHARTER OF DEMANDS </h2>
                      <br></br>
                      <span class="sub-heading-content">
                        This Charter of Demands is a foundational fundamental
                        document outlining the specific needs and rights of
                        homeless persons, solely by virtue of their birth as
                        fellow human beings. Each demand stems from the pressing
                        issues and challenges they encounter in their daily
                        lives. Compiled and presented annually by the collective
                        members themselves,at the central conference of the
                        urban homeless women’s collective, the charter
                        highlights the critical areas of intervention for
                        tangible positive shifts in the quality of the lives of
                        the homeless. Addressing the same would pave the way for
                        a more inclusive and just society-
                      </span>
                      <div className="Points" style={{ display: "flex" }}>
                        <div className="left">
                          <div class="card">
                            <h3>Point 1</h3>
                            <p>
                              {" "}
                              1. Homeless Women’s Livelihood & Employment:Offer
                              skill training programmes and employment
                              opportunities tailored specifically for homeless
                              women.
                            </p>
                          </div>
                          <div class="card">
                            <h3>Point 2</h3>
                            <p>
                              2. ICDS Schools & Crèches Expansion: Increase the
                              number of ICDS (Integrated Child Development
                              Services) schools and build more crèches for the
                              children of homeless women to ensure their
                              well-being and development.
                            </p>
                          </div>
                          <div class="card">
                            <h3>Point 3</h3>
                            <p>
                              {" "}
                              3. Sanitation & Water Facilities: Construct more
                              toilets and bathrooms in areas with high
                              concentrations of homeless populations, and ensure
                              the provision of safe drinking water.
                            </p>
                          </div>
                          <div class="card">
                            <h3>Point 4</h3>
                            <p>
                              4.Access to Electricity: Facilitate electricity
                              provisions, especially in areas under bridges,
                              near railway stations, and on footpaths. This
                              includes establishing proper metering systems.
                            </p>
                          </div>
                          <div class="card">
                            <h3>Point 5</h3>
                            <p>
                              {" "}
                              5. Homeless Women’s Safety & Security: Implement
                              measures, both preventive and responsive, to
                              ensure the safety and security of homeless women
                              in all areas.
                            </p>
                          </div>
                        </div>
                        <div className="right">
                          <div class="card">
                            <h3>Point 6</h3>
                            <p>
                              {" "}
                              6. Promotion of Self-Help Groups (SHG):Promote SHG
                              groups in all the field areas where the collective
                              is operational, thereby amplifying community-led
                              initiatives and financial empowerment.
                            </p>
                          </div>
                          <div class="card">
                            <h3>Point 7</h3>
                            <p>
                              7. Efficient Drainage Systems:Construct proper
                              drainage systems to prevent flooding and related
                              hazards.
                            </p>
                          </div>
                          <div class="card">
                            <h3>Point 8</h3>
                            <p>
                              {" "}
                              8. Educational Rights of Vulnerable Homeless
                              Children: Protect and promote the right to
                              education for every child, regardless of their
                              socioeconomic status, ensuring inclusive
                              educational opportunities.
                            </p>
                          </div>
                          <div class="card">
                            <h3>Point 9</h3>
                            <p>
                              9. Right to a Hygienic Environment: Promote and
                              implement measures to maintain cleanliness in
                              environments populated by homeless communities to
                              prevent diseases.
                            </p>
                          </div>
                          <div class="card">
                            <h3>Point 10</h3>
                            <p>
                              10. Adult Literacy:Initiate programmes dedicated
                              to imparting fundamental education to homeless
                              adults, enabling them to sign and engage in basic
                              literacy activities.
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </li>
              </ul>
            </div>

            {/* <div class="space"></div>
      <Description />
      <div class="space"></div>
      <Blogs />
      <div class="space"></div>
      <Involve />
      <div class="space"></div>
      <Moments />
      <div class="space"></div>
      <Subscribe /> */}
            {/* <div class="space"></div>
      <MyComponent /> */}
            <div class="space"></div>
            <MapComponent />
            <div id="howwework" class="space"></div>
            <div>
              <section id="team">
                <h2 class="sub-heading">Duties of the office bearers-</h2>
                <br></br>
                <img
                  src={howweworkimage}
                  style={{ textAlign: "center", paddingLeft: "260px" }}
                ></img>
              </section>

              <ul>
                <li class="team-member">
                  The structure of Women’s Collective{" "}
                </li>
                <br></br>
                <div className="Points" style={{ display: "flex" }}>
                  <div className="left">
                    <div class="card">
                      <li>
                        • The first line leaders of WC, functioning as a central
                        team to plan as well as execute programmes according to
                        the charter of demands and other expansion of work.
                      </li>
                    </div>
                    <div class="card">
                      <li>
                        • The members of the areas in which the collective is
                        operating were asked to nominate a person who would
                        function as representative/2nd line leaders of the
                        respective areas. The person will effectively be a link
                        between the central team and the areas they will be
                        representing.
                      </li>
                    </div>
                  </div>
                  <div className="right">
                    <div class="card">
                      <li>
                        • The members of the collective will be at the ground
                        level- those who will participate, discuss, deliberate
                        and initiate social action programmes to demand their
                        rights and other basic necessities.
                      </li>
                    </div>
                  </div>
                </div>
              </ul>
            </div>

            <div class="space"></div>
            <Footer />
          </div>
        )}
      </div>
    </Styles>
  );
};
export default Project;
