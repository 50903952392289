import 'bootstrap/dist/css/bootstrap.min.css'
function Involve(){
    return(
        <div>
         <section class="donor-section pt-100 pb-70">
            <div class="container-fluid">
                <div class="section-title">
                    <h2> Get Involved </h2>
                </div>

                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="donor-item">
                            <a href="https://isankalpa.org/volunteer/">
                             <div class="icon">
                                <i class="flaticon-support"></i>
                             </div>
                             <h3>Volunteer</h3></a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="donor-item">
                             <a href="https://isankalpa.org/internship/">
                            <div class="icon">
                                 <img src="https://isankalpa.org/wp-content/themes/iswar/assets/img/internship.png"></img>
                            </div>
                            <h3>Internship</h3>
                        </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 offset-md-3 offset-lg-0">
                        <div class="donor-item">
                             <a href="https://isankalpa.org/career/">
                            <div class="icon">
                                <i class="flaticon-confirmation"></i>
                            </div>
                            <h3>Career</h3>
                        </a>
                        </div>
                    </div>

                     <div class="col-lg-3 col-md-6 offset-md-3 offset-lg-0">
                        <div class="donor-item">
                             <a href="https://isankalpa.org/be-a-changemaker/">
                            <div class="icon">
                                <img src="https://isankalpa.org/wp-content/themes/iswar/assets/img/changemakker.png"></img>
                            </div>
                            <h3>Change Maker</h3>
                        </a>
                        </div>
                    </div>

                </div>
            </div>

            <div class="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </section>
        </div>
    )
}
export default Involve;