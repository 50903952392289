import React, { useState } from "react";

import womencollectivelogo from "../logo/women-collective-image-3.png";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Styles = styled.div`
  .tabs-container {
    display: flex;
    margin: 100px;
    padding: 30px;
  }
  .button {
    align-items: center;
    background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
    border: 0;
    border-radius: 10px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 15px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 280px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
  }

  .button:active,
  .button:hover {
    outline: 0;
  }

  .button span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
  }

  .button:hover span {
    background: none;
  }

  @media (min-width: 768px) {
    .button {
      font-size: 16px;
      min-width: 196px;
    }
  }
`;

function ImageUpload() {
  return (
    <Styles>
      <div>
        <a class="navbar-brand" href="http://muktokantho.co.in/admin-login">
          <img
            src={womencollectivelogo}
            class="white-logo"
            alt="logo"
            style={{ width: "10%", height: "80px" }}
          ></img>
        </a>

        <div className="tabs-container">
          <Link
            to="/admin-images-upload"
            style={{
              marginLeft: "20%",
              marginRight: "20%",
              textDecoration: "none",
            }}
          >
            <button class="button" role="button">
              <span class="text">Upload Images</span>
            </button>
          </Link>
          <div class="space"></div>
          <Link
            to="/admin-location-upload"
            style={{ textDecoration: "none" }}
          >
            <button class="button" role="button">
              <span class="text">Upload Location</span>
            </button>
          </Link>
        </div>
      </div>
    </Styles>
  );
}
export default ImageUpload;
