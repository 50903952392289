import React, { useState } from "react";
//import { useHistory } from 'react-router-dom';
import "../Styles/Login.css";
import womencollectivelogo from "../logo/women-collective-image-3.png";
import { Redirect } from "react-router-dom";
import Header from "./Header";
import axios from "axios";
import styled from "styled-components";

const Styles = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: serif;
  }

  body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: -webkit-linear-gradient(to right, #272727, #000000);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #202020, #000000);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 100%;
    overflow: hidden;
  }

  .container {
    position: relative;
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container span {
    position: absolute;
    inset: 0;
    border: 2px solid #fff;
    transition: 0.5s;
  }

  .container span:nth-child(1) {
    border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
    animation: animate 6s linear infinite;
  }

  .container span:nth-child(2) {
    border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
    animation: animate 4s linear infinite;
  }

  .container span:nth-child(3) {
    border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
    animation: animate2 10s linear infinite;
  }

  .container:hover span {
    border: 6px solid var(--clr);
    filter: drop-shadow(0 0 20px var(--clr));
  }

  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes animate2 {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  .form-container {
    position: absolute;
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .form-container h2 {
    font-size: 2em;
    color: #fff;
  }

  .input-container {
    position: relative;
    width: 100%;
  }

  .input-container input {
    position: relative;
    width: 100%;
    padding: 12px 20px;
    background: transparent;
    border: 2px solid #fff;
    border-radius: 40px;
    font-size: 1.2em;
    color: #fff;
    box-shadow: none;
    outline: none;
  }

  .input-container button[type="submit"] {
    width: 100%;
    background: #0078ff;
    background: -webkit-linear-gradient(to right, #240b36, #c31432);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #240b36, #c31432);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border: none;
    cursor: pointer;
  }

  .input-container input::placeholder {
    color: rgba(255, 255, 255, 0.75);
  }

  .links-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }

  .links-container a {
    color: #fff;
    text-decoration: none;
  }
`;
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logIn, setLogIn] = useState();
  //const history = useHistory();

  const handleLogin = async (e) => {
    try{
    e.preventDefault();
    // const loginSuccessful=true;
    // if(loginSuccessful){
    //     setLogIn(true)
    // }

    // console.log('Email:', email);
    // console.log('Password:', password);
    // if(logIn){
    //     window.location.href="/admin-dashboard";
    // }

    //history.push('/admin-dashboard');
    const response1=await axios.post('https://admin.muktokantho.co.in/api/uicomponenet/login-api',{email,password})
   console.log("hi",response1.data)
      if(response1.data.message=='Login successfully'){
        
        window.location.href="/admin-dashboard";
    }
  }
  catch(error){
    console.error("Error Fetching")
  }
  };

  return (
    <div style={{ backgroundColor: "black" }}>
      <Styles style={{ marginLeft: "30%", height: "690px" }}>
        <a class="navbar-brand" >
          <img
            src={womencollectivelogo}
            class="white-logo"
            alt="logo"
            style={{ width: "20%", height: "80px", marginLeft: "-43%" }}
          ></img>
        </a>
        <form onSubmit={handleLogin}>
        <div class="container">
          <span style={{ color: "#eeff00" }}></span>
          <span style={{ color: "00ffdd" }}></span>
          <span style={{ color: "e900d5" }}></span>
          <div class="form-container">
            <h2>Login form</h2>
            <div class="input-container">
              <input
                type="email"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
                required
              />
            </div>
            <div class="input-container">
              <input
                type="password"
                value={password}
                name="password"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div class="input-container">
              <button type="submit">Login</button>
            </div>
            {/* <div class="links-container">
              <a href="#">Forget Password</a>
              <a href="#">Signup</a>
            </div> */}
          </div>
        </div>
        </form>
      </Styles>
    </div>
  );
};
export default Login;
// <div>
//
//   <form onSubmit={handleLogin}>
//     <div class="container">
//       <div>
//         <label for="email">Email Address:&nbsp;&nbsp;</label>
//         <input
//           type="email"
//           value={email}
//           name="email"
//           onChange={(e) => setEmail(e.target.value)}
//           placeholder="Enter email"
//           required
//         />
//       </div>
//       <div>
//         <label for="password">
//           Password:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//         </label>
//         <input
//           type="password"
//           value={password}
//           name="password"
//           placeholder="Enter password"
//           onChange={(e) => setPassword(e.target.value)}
//           required
//         />
//       </div>
//     </div>
//     <div class="container" style={{ backgroundColor: "#f1f1f1" }}>
//       <button type="submit">Login</button>
//     </div>
//   </form>
// </div>
