import womencollectivelogo from "../logo/women-collective-image-3.png";
import axios from "axios";
import { useState,useEffect } from "react";
import styled from "styled-components";
import LandingImage1 from "../images/Landing Image 1.jpeg";
import LandingImage2 from "../images/Landing Image 2.jpeg";
import LandingImage3 from "../images/Landing Image 3.jpeg";
import LandingImage4 from "../images/Landing Image 4.jpeg";


const Styles = styled.div`
.image-container{
  display:grid;
  grid-template-columns:1fr 1fr;
  justify-content:center;
  align-items:center;
  gap:100px
}
.image-container .left{
  margin-right:10px;
  
  
}
.image-container .right{
  margin-left:10px;
  
  
}`


function Image() {
  const [data, setData] = useState([]);

  
  useEffect(()=>{
    const apicall=async ()=>{
    try{
      let images1=[];
      const response= await axios.get('https://admin.muktokantho.co.in/api/uicomponenet/admin-images-upload');
      console.log("ki2",response.data.components);
      let result=await response.data.components;
      
      result.forEach(d=>{
        if(d.component_type==='Image' && d.component_position ==='gallery'){
      
        images1.push(d.component_path)
      
        }
      
    })
      console.log("kdkdk",images1);
      setData(images1)
      
    }
    catch(error){
      console.log("Error fetching apidata:",error)
    }
    };
    apicall();
  },[])

  
  return (
    <Styles>
      <div>
        <div class="space"></div>
        <div class="space"></div>
        <div class="navbar-area">
          <div class="huruma-nav">
            <div class="container-fluid">
              <nav
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                }}
              >
                <a class="navbar-brand" href="http://muktokantho.co.in/">
                  <img
                    src={womencollectivelogo}
                    class="white-logo"
                    alt="logo"
                    style={{ width: "200px", height: "80px", border: "10px" }}
                  ></img>
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div class="space"></div>
        <div className="image-container">
         {data && data.map((path,index)=>(

       
          <div key={index} className={index%2===0?'right':'left'}>
          
             
            <img
              style={{
                
                display:"block",
                width:"100%",
                objectFit:"cover"
                
              }} 
              src={path}
            ></img>
          </div>
 
          
         ))}
          </div>
         
 
      
             
        
        
        
      </div>
    </Styles>
  );
}
export default Image;
