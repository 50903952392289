import 'bootstrap/dist/css/bootstrap.min.css'
import womencollectivelogo from "../logo/women-collective-image-3.png";
function Footer(){
    return(
        <div>
        <footer id="footer" class="footer-section pt-100">
            <div class="container1">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="single-footer-widget">
                            <h3>Contact Details</h3>
                            <span>Shibani Adhikatri,President,<br></br>Maniktola Muktokanta Mahila Samity,<br></br>Ph no: 7003020990</span><br></br>
                            <div class="space"></div>
                            <span>Kaikasha Shakil,Vice President,<br></br>Maniktola Muktokanta Mahila Samity,<br></br>Ph no: 8240430635</span>
                            <div class="space"></div>
                            <div class="footer-info-contact">
                                <i class="flaticon-pin"></i>
                                <h3>Registered Address</h3>
                                <span>223/1G, <br></br>Satin Sen Sarani,<br></br>Manicktala,<br></br>Kolkata:700054,<br></br>West Bengal</span>
                            </div>

                            <div class="footer-info-contact">
                                <i class="flaticon-call"></i>
                                <h3>Call Us</h3>
                                <span><a href="tel:+91 7980427142"> +91 7980427142</a> (Susmita das)/ <a href="tel:+916289079248">+91 6289079248</a> (Afsari Begum)</span>
                            </div>

                            <div class="footer-info-contact">
                                <i class="flaticon-email"></i>
                                <h3>Email Us</h3>
                                <span>
                                <a href="muktokanthomahilasamity@gmail.com">muktokanthomahilasamity@gmail.com</a>
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* <div class="col-lg-5 col-md-6 col-sm-6">
                        <div class="single-footer-widget footquote">

                            <h3>"To deny people their human rights is to challenge their very humanity"</h3>
~ Nelson Mandela
                        </div>
                    </div> */}

                   

                    {/* <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-footer-widget">
                            <h3>Recent News</h3> */}


                                                         
		
		{/* <div class="fb-like-box fb_iframe_widget" data-href="https://www.facebook.com/iswarsankalpa" data-colorscheme="dark" data-show-faces="false" data-stream="true" data-header="false" data-border-color="" fb-xfbml-state="rendered" fb-iframe-plugin-query="app_id=&amp;color_scheme=dark&amp;container_width=350&amp;header=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fiswarsankalpa&amp;locale=en_US&amp;sdk=joey&amp;show_faces=false&amp;stream=true"><span style={{"verticalAlign": "bottom"," width": "300px", "height": "395px"}}><iframe name="f22c0c28df424ec" width="1000px" height="1000px" data-testid="fb:like_box Facebook Social Plugin" title="fb:like_box Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/plugins/like_box.php?app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Dffb7f913dff894%26domain%3Disankalpa.org%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fisankalpa.org%252Ff3d3501fa47d18%26relation%3Dparent.parent&amp;color_scheme=dark&amp;container_width=350&amp;header=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fiswarsankalpa&amp;locale=en_US&amp;sdk=joey&amp;show_faces=false&amp;stream=true" style={{"border": "none"," visibility": "visible", "width": "300px", "height": "395px"}} class=""></iframe></span></div> */}

		    
                            
                                                   
                     

                        

                           

                           
                        {/* </div>
                    </div> */}
                </div>
            </div>

            <div class="copyright-area">
                <div class="container">
                    <div class="copyright-area-content">
                        <div class="row align-items-center">
                            <div class="col-lg-4">
                                <div class="copyright-logo">
                                    <img src={womencollectivelogo} alt="image" style={{"height":"250px"}}></img>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <p>
                                    <i class="fa fa-copyright"></i> 
                                    2024 Women Collective. All Rights Reserved
                                    <a href="https://envytheme.com/" target="_blank">
                                       EnvyTheme 
                                    </a> 
                                </p>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                 <div class="menu-footer-menu-container"><ul id="menu-footer-menu" class=""><li id="menu-item-298" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-298 nav-item"><a class="nav-link" rel="privacy-policy" href="https://isankalpa.org/privacy-policy/">Privacy Policy</a></li>
<li id="menu-item-300" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-300 nav-item"><a class="nav-link" href="https://isankalpa.org/terms-condition/">Terms &amp; Conditions</a></li>
</ul></div>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </div>
    )
}
export default Footer;