// import "../Styles/Navbar.css";
import womencollectivelogo from "../logo/women-collective-image-3.png";
import styled from "styled-components";

const Styles = styled.div`
  ul {
    display: inline-block;
  }
  ul li {
    list-style-type: none;
  }
  a {
    text-decoration: none;
    color: black;
    transition: all 0.4s;
  }
  .logo {
    font-size: 16px;
    padding: 0 10px;
  }
  .menu a {
    display: block;
    padding: 10px;
  }
  .menu-item {
    position: relative;
  }
  div#dropdown {
    font-size: 15px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    padding: 20px 0;
    z-index: 1;
    width: 250px;
    box-shadow: 0 10px 10px 3px rgba(red, green, blue, alpha);
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s;
    transform: translateY(-20px);
  }
  .menu-item:hover div#dropdown {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
  div#dropdown a {
    color: #444;
    padding-top: 2px;
    padding-left: 30px;
  }

  div#dropdown a:hover {
    color: red;
    padding-left: 50px;
  }
  .menu-item a:hover {
    color: red;
    padding-left: 30px;
  }
  .button {
    white-space: nowrap;
    border: 1px solid transparent;
    color: #ffffff;
    background-color: #20013e;
    transition: 0.5s;
    border-radius: 50px;
    font-size: 16px;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .button:hover {
    color: white;
    background-color: green;
  }
  @media screen and (min-width: 900px) {
    .navbar-brand {
      width: 400px;
    }
  }
`;
function Navbar() {
  return (
    <Styles>
      <div class="navbar-area">
        {/* <div class="huruma-responsive-nav">
            <div class="container">
                <div class="huruma-responsive-menu">
                    <div class="logo">
                        <a href="https://isankalpa.org/">
                            <img src="https://isankalpa.org/wp-content/themes/iswar/assets/img/logo.png" class="white-logo" alt="logo"></img>
                            <img src="https://isankalpa.org/wp-content/themes/iswar/assets/img/logo-2.png" class="black-logo" alt="image"></img>
                        </a>
                    </div>
                </div>
            </div>
        </div> */}

        <div class="huruma-nav">
          <div class="container-fluid">
            <nav
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
              }}
            >
              <a class="navbar-brand" href="http://muktokantho.co.in/">
                <img
                  src={womencollectivelogo}
                  class="white-logo"
                  alt="logo"
                  style={{ width: "80%", height: "80px", }}
                ></img>
              </a>
              <div
                id="navbarSupportedContent"
                class="collapse navbar-collapse mean-menu"
                style={{
                  display: "block",
                  marginLeft: "7rem",
                  marginRight: "7rem",
                }}
              >
                <ul class="menu">
                  <li class="menu-item">
                    <a href="#" class="logo">
                      About Us
                    </a>
                    <div id="dropdown">
                      <a href="#story">Our Story</a>

                      <a href="#team">Our Team</a>

                      <a href="#charter">Charter Of Demands</a>
                    </div>
                  </li>
                </ul>
                <ul class="menu">
                  <li class="menu-item">
                    <a href="#howwework" class="logo">
                      How We Work
                    </a>
                  </li>
                </ul>
                <ul class="menu">
                  <li class="menu-item">
                    <a class="logo" href="#footer">
                      Get Involved
                    </a>
                  </li>
                </ul>
                <ul class="menu">
                  <li class="menu-item">
                    <a href="/ourwork" target="_blank" class="logo">
                      Our Work
                    </a>
                  </li>
                </ul>
                <ul class="menu">
                  <li class="menu-item">
                    <a class="logo" href="#map">
                      Where We Work
                    </a>
                  </li>
                </ul>
                <ul class="menu">
                  <li class="menu-item">
                    <a class="logo" href="#footer">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              {/* </div>  */}
            </nav>
          </div>
        </div>
      </div>
    </Styles>
  );
}

export default Navbar;
