import React,{useState} from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

function Updateupload(){
    const[component_type,setcomponent_type]=useState('');
    const[component_path,setcomponent_path]=useState('');
    const[file,setFile]=useState();
    const[component_text,setcomponent_text]=useState('');
    const[component_position,setcomponent_position]=useState('');
    const{component_id}=useParams();
    const navigate=useNavigate();
    function handleSubmit(event){
        event.preventDefault();
        console.log("tsggs3",file)
        const formdata=new FormData();
        console.log("tsggs4",formdata)
        formdata.append('image',file)
        formdata.append('component_id',component_id)
        formdata.append('component_text',component_text)
        console.log("tsggs5",formdata)
        
        for (const [key, value] of formdata) {
            console.log(key+" "+value);
           }
        axios.put('https://admin.muktokantho.co.in/api/uicomponenet/update-images-upload/'+component_id,formdata)
        .then(res=>{
            console.log(res);
            navigate('/admin-images-upload')
        }).catch(err=>console.log(err))
    
    }

    return(
        <div>
            <div className=''>
                <div className=''>
                    <form onSubmit={handleSubmit}>
                        <h2>Update Image</h2>
                    
                        <div className='mb-2'>
                            <label htmlFor=''>Component Text</label>
                            <input type='text' placeholder='Enter Component Text' onChange={e=>setcomponent_text(e.target.value)}/>
                        </div>
                        <div className='mb-2'>
                            <label htmlFor=''>Component Path</label>
                            <input type='file' placeholder='Enter Component Path' onChange={e=>setFile(e.target.files[0])}/>
                        </div>
                    
                        <button>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default Updateupload;