import React,{useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Createupload(){
    const[component_id,setcomponent_id]=useState('');
    const[component_type,setcomponent_type]=useState('Image');
    const[file,setFile]=useState();
    const[component_text,setcomponent_text]=useState('');
    const[component_position,setcomponent_position]=useState('');
    const navigate=useNavigate();
    function handleSubmit(event){
        event.preventDefault();
        console.log("tsggs",file)
        const formdata=new FormData();
        console.log("tsggs1",formdata)
        formdata.append('image',file)
        formdata.append('component_type',component_type)
        formdata.append('component_text',component_text)
        formdata.append('component_position',component_position)
        console.log("tsggs2",formdata)
        
        console.log(formdata)
        for (const [key, value] of formdata) {
            console.log(key+" "+value);
           }
        axios.post('https://admin.muktokantho.co.in/api/uicomponenet/create-images-upload',formdata)
        .then(res=>{
            console.log(res);
            navigate('/admin-images-upload')
        }).catch(err=>console.log(err))
    }

    return(
        <div>
            <div className=''>
                <div className=''>
                    <form onSubmit={handleSubmit}>
                        <h2>Add Image</h2>
                        {/* <div className='mb-2'>
                            <label htmlFor=''>Component ID</label>
                            <input type='text' placeholder='Enter ComponentId' onChange={e=>setcomponent_id(e.target.value)}/>
                        </div> */}
                        <div className='mb-2'>
                            <label htmlFor=''>Component Type</label>
                            <input type='text' placeholder='Image' value="Image" />
                        </div>
                        <div className='mb-2'>
                            <label htmlFor=''>Component Path</label>
                            <input type='file' placeholder='Enter Component Path' onChange={e=>setFile(e.target.files[0])}/>
                        </div>
                        <div className='mb-2'>
                            <label htmlFor=''>Component Text</label>
                            <input type='text' placeholder='Enter Component Text' onChange={e=>setcomponent_text(e.target.value)}/>
                        </div>
                        <div className='mb-2'>
                          
                            <label for="pageType">Component Position</label>
  <select id="pageType" name="pageType" onChange={e=>setcomponent_position(e.target.value)}>
    <option value="" >Choose</option>
    <option value="landing" >Landing Page</option>
    <option value="gallery">Gallery Page</option>

  </select>
 
                              {/* <label htmlFor=''>Component Position</label>
                            <input type='text' placeholder='Enter Position' onChange={e=>setcomponent_position(e.target.value)}/> */}

                        </div>
                        <button>Submit</button>
                    </form>
                     <p>Selected option : {component_position}</p>
                </div>
            </div>
        </div>
    )
}
export default Createupload;