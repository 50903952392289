import React, { useState, useEffect } from "react";
import womencollectivelogo from "../logo/women-collective-image-3.png";
import axios from "axios";
import { Link } from "react-router-dom";

import styled from "styled-components";
const Styles = styled.div`
  .styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width: -webkit-fill-available;
    margin-left: 1%;
  }

  .styled-table thead tr {
    background-color: #20013e;
    color: #ffffff;
    text-align: left;
  }

  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
  }
 
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #20013e;
  }
  .active-row:hover {
    background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  }
  .button {
    align-items: center;
    background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 20px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
  }

  .button:active,
  .button:hover {
    outline: 0;
  }

  .button span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
  }

  .button:hover span {
    background: none;
  }

  .table-container{
    max-height:300px;
    overflow-y:auto
  }

  @media (min-width: 768px) {
    .button {
      max-width: 20px;
    }
  }
`;

function Locationupload() {
  const [getUploadedLocationData, setUploadedLocationData] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get("http://localhost:8000/api/uicomponenet/admin-location-upload")
  //     .then((res) => setUploadedLocationData(res.data.components))
  //     .catch((err) => console.log(err));
  // }, []);
  useEffect(()=>{
    const apicall1=async ()=>{
    try{
      let table2=[];
      const response1= await axios.get('https://admin.muktokantho.co.in/api/uicomponenet/admin-location-upload');
      console.log("ki1",response1.data.components)
      let result2=await response1.data.components;
      
      result2.forEach(d=>{
        if(d.component_type==='Map' ){
      
        table2.push(d)
      
        }
      })
      
    
 
    console.log("kdkdk22",table2);
    setUploadedLocationData(table2);
    
  }
  catch(error){
    console.log("Error fetching apidata:",error)
  }
  };
  apicall1();
},[])
  return (
    <Styles>
      <div>
        <a class="navbar-brand" href="http://muktokantho.co.in/admin-dashboard">
          <img
            src={womencollectivelogo}
            class="white-logo"
            alt="logo"
            style={{ width: "10%", height: "80px" }}
          ></img>
        </a>
        <div class="space"></div>

        <div style={{ width: "100%" }} className="table-container">
          <table className="styled-table">
            <thead>
              <tr>
                <th >Component ID</th>
                <th > Component Path</th>
              </tr>
            </thead>
            <tbody>
              {getUploadedLocationData.map((data, i) => (
                <tr class="active-row">
                  
                    
                      <td>{data.component_id}</td>

                      <td>{data.component_path}</td>

                      <td>
                        <button>
                          <Link
                            style={{ textDecoration: "none", color: "#ffffff" }}
                            to={`updatelocation/${data.component_id}`}
                          >
                            Update
                          </Link>
                        </button>
                      </td>
                    
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Styles>
  );
}
export default Locationupload;

{
  /* <table class="styled-table">
    <thead>
        <tr>
            <th>Name</th>
            <th>Points</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Dom</td>
            <td>6000</td>
        </tr>
        <tr class="active-row">
            <td>Melissa</td>
            <td>5150</td>
        </tr>
        <!-- and so on... -->
    </tbody>
</table> */
}
